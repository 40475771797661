<template>
  <li
    class="subnav__items"
    ref="subnavItem"
    :class="{ 'is-active-sub' : active }"
  >
  <!-- :style="`margin-top: ${subnavitem_h}px`" -->
    <div class="nav__title nav__title--sub">
      <span>
        <slot name="label"></slot>
      </span>

      <span
        class="nav__count"
        v-if="count"
      >
        {{ count }}
      </span>

      <router-link
        :to="path"
        v-if="path"
      >
      </router-link>
    </div>

    <ul
      class="subnav"
      v-if="hasSubnav"
    >
      <slot name="subnav"></slot>
    </ul>
  </li>
</template>

<script>

export default {
  name: 'SubNavItem',

  props: {
    path: [ String ],
    defaultPath: [ String, Array ],
    count: [String, Number]
  },

  data () {
    return {
      subnavitem_h: null,
      active: false,
      group: null,

      dPath: null,
      currentPath: null
    }
  },

  watch: {
    async '$route' ($router) {
      this.validateRoute()
    }
  },

  computed: {
    hasSubnav () {
      return this.$slots['subnav']
    }
  },

  inject: {
    handleNav: {}
  },

  mounted () {
    let subnavItem = this.$refs.subnavItem

    if (this.$refs.subnavItem) {
      this.subnavitem_h = Math.abs(parseInt(subnavItem.clientHeight + 5)) * -1
    }

    this.$nextTick(() => {
      let parent = this.$parent.$parent
      this.group = parent.group


      if (this.$parent.$parent.$el.classList.contains('subnav__items')) {
        this.group = this.$parent.$parent.$parent.$parent.group

        console.log(this.group)
      }

      this.validateRoute()
    })
  },

  methods: {
    validateRoute () {
      this.$parent.$parent.active = false
      this.active = false

      this.currentPath = this.$route.path
      this.dPath = this.defaultPath || this.path

      this.$nextTick(() => {
        if (typeof this.dPath === 'object') {
          for (var path in this.dPath) {
            let thisPath = this.dPath[path]

            if (this.currentPath.indexOf(thisPath) > -1) {
              if (this.group === this.$parent.$parent.group) {
                this.active = true
                this.$parent.$parent.active = true
              }
            }
          }
        } else {
          if (this.currentPath.indexOf(this.dPath) > -1) {
            if (this.group === this.$parent.$parent.group) {
              this.active = true
              this.$parent.$parent.active = true
            }
          }
        }
      })
    }
  }
}
</script>
